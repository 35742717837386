import { createContext, useCallback, useContext, useRef, useState } from 'react'
import type { PropsWithChildren, ReactNode } from 'react'

const MESSAGE_TIMEOUT = 1000

type ScreenReaderProvider = {
  setScreenReaderMessage: (message: ReactNode) => void
}

const ScreenReaderContext = createContext<ScreenReaderProvider | null>(null)

const useScreenReader = (): ScreenReaderProvider =>
  useContext(ScreenReaderContext) as ScreenReaderProvider

function ScreenReaderProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element {
  const [message, setMessage] = useState<ReactNode>(null)
  const messageTimer = useRef<ReturnType<typeof setTimeout> | null>(null)

  const setScreenReaderMessage = useCallback((message: ReactNode) => {
    messageTimer.current = null

    setMessage(message)

    messageTimer.current = setTimeout(() => {
      setMessage(null)
    }, MESSAGE_TIMEOUT)
  }, [])

  return (
    <ScreenReaderContext.Provider
      value={{
        setScreenReaderMessage,
      }}
    >
      <div
        className="absolute h-0 overflow-hidden"
        role={message ? 'status' : undefined}
      >
        {message}
      </div>
      {children}
    </ScreenReaderContext.Provider>
  )
}

export { ScreenReaderProvider, useScreenReader }
