export enum SupportedLocale {
  English = 'en',
  French = 'fr',
  Spanish = 'es',
}

function current(): SupportedLocale {
  return document.documentElement.lang as SupportedLocale
}

export default { current }
