import { createContext, useContext, useState } from 'react'
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react'

interface ErrorState {
  errorId: string | undefined
  setErrorId: Dispatch<SetStateAction<string | undefined>>
}

const ErrorContext = createContext<ErrorState | null>(null)

function useError(): ErrorState {
  return useContext(ErrorContext) as ErrorState
}

function ErrorProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element {
  const [errorId, setErrorId] = useState<string | undefined>()

  return (
    <ErrorContext.Provider value={{ errorId, setErrorId }}>
      {children}
    </ErrorContext.Provider>
  )
}

export { ErrorProvider, useError }
