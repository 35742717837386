import { lazy as _lazy } from 'react'
import type { ComponentType } from 'react'

// We use `any` to allow passing props to the lazy-loaded components
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function lazy(component: () => Promise<{ default: ComponentType<any> }>) {
  return _lazy(() => load(component))
}

function load(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: () => Promise<{ default: ComponentType<any> }>,
  attempts = 3,
  interval = 1000,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<{ default: ComponentType<any> }> {
  return new Promise((resolve, reject) => {
    component()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attempts === 1) {
            reject(error)

            return
          }

          load(component, attempts - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export default { lazy }
