import type { PropsWithChildren, SetStateAction } from 'react'
import { createContext, useCallback, useContext, useState } from 'react'
import { IntlProvider } from 'react-intl'
import Locales from '../helpers/Locales'
import messages from '../locales'
import { ErrorProvider } from './ErrorContext'
import { ScreenReaderProvider } from './ScreenReaderContext'
import { SentryProvider } from './SentryContext'

interface AppState<ExpectedState> {
  appState: ExpectedState
  setAppState: (
    newState: Partial<ExpectedState> | SetStateAction<ExpectedState>,
  ) => void
}

const AppContext = createContext<AppState<Record<string, unknown>> | null>(null)

function useAppState<ExpectedState>(): AppState<ExpectedState> {
  return useContext(AppContext) as AppState<ExpectedState>
}

function AppProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element {
  const [state, setState] = useState(() => window.state || {})

  const setAppState = useCallback(
    (
      newState:
        | Record<string, unknown>
        | SetStateAction<Record<string, unknown>>,
    ) => {
      if (typeof newState === 'function') {
        setState(newState)
      } else {
        setState((prevState) => ({ ...prevState, ...newState }))
      }
    },
    [],
  )

  const locale = Locales.current()

  return (
    <AppContext.Provider value={{ appState: state, setAppState }}>
      <ErrorProvider>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <SentryProvider>
            <ScreenReaderProvider>{children}</ScreenReaderProvider>
          </SentryProvider>
        </IntlProvider>
      </ErrorProvider>
    </AppContext.Provider>
  )
}

export { AppProvider, useAppState }
