import * as locales from '@coconut-software/ui/dist/locales'
import type { SupportedLocale } from '../helpers/Locales'
import en from './en'
import es from './es'
import fr from './fr'

interface AvailableLocales {
  [SupportedLocale.English]: Record<string, string>
  [SupportedLocale.French]: Record<string, string>
  [SupportedLocale.Spanish]: Record<string, string>
}

export default {
  en: { ...en, ...locales.en },
  es: { ...es, ...locales.es },
  fr: { ...fr, ...locales.fr },
} as AvailableLocales
