import 'regenerator-runtime'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Suspense } from 'react'
import type { ComponentType, LazyExoticComponent } from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { AppProvider } from './contexts/AppContext'
import Files from './helpers/Files'

type Domain = 'chat' | 'connect' | 'login' | 'tools'

export type PendoEventData = Record<string, string | boolean>

interface PendoMetadata {
  account: Record<string, unknown>
  visitor: Visitor
}

interface Visitor {
  id: string
  email: string
  full_name: string
}

type Pendo = {
  getVisitorId: () => string
  getSerializedMetadata: () => PendoMetadata
  isReady: () => boolean
  identify: (metadata: PendoMetadata) => void
  location: {
    setUrl: (url: string) => void
  }
  track: (name: string, data: PendoEventData) => void
}

declare global {
  interface Window {
    env: string
    pendo: Pendo | undefined
    state: Record<string, unknown>
  }

  interface HTMLMediaElement {
    setSinkId?(sinkId: string): Promise<undefined>
  }
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: `https://10ebf6cbd35b4f23a53ef77c4c87f64b@o206331.ingest.sentry.io/5786724`,
    environment: window.env,
    integrations: [new BrowserTracing()],
    normalizeDepth: 4,
    tracesSampleRate: 0.2,
  })
}

const domain = window.location.host.split('.')[0] as Domain

const applications: Record<Domain, LazyExoticComponent<ComponentType>> = {
  chat: Files.lazy(() => import('./applications/Chat')),
  connect: Files.lazy(() => import('./applications/Connect')),
  login: Files.lazy(() => import('./applications/Login')),
  tools: Files.lazy(() => import('./applications/Tools')),
}

const App = applications[domain]

render(
  <AppProvider>
    <Router>
      <Suspense fallback={<div />}>
        <App />
      </Suspense>
    </Router>
  </AppProvider>,
  document.getElementById('root'),
)
