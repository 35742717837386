import * as Sentry from '@sentry/react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import type { SetStateAction, PropsWithChildren } from 'react'
import type {
  TwilioComponent,
  TwilioComponentStatus,
} from '../helpers/Components'

type SentryConnectState = {
  audioTrackExists: boolean
  components?: Record<TwilioComponent, TwilioComponentStatus>
  connectedAt: string[]
  disconnectedAt: string[]
  isCameraEnabled: boolean
  isCoBrowsing: boolean
  isMicrophoneEnabled: boolean
  isReviewingDocuments: boolean
  isScreenSharing: boolean
  page: string
  mediaError: Error | null
  selectedExtension: string | null
  videoTrackExists: boolean
}

type SentryState = SentryConnectState

type SentryProvider = {
  setSentryState: (
    newState: Partial<SentryState> | SetStateAction<SentryState>,
  ) => void
}

const SentryContext = createContext<SentryProvider | null>(null)

const useSentry = (): SentryProvider =>
  useContext(SentryContext) as SentryProvider

function SentryProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element {
  const [state, setState] = useState<SentryState>({
    audioTrackExists: false,
    connectedAt: [],
    disconnectedAt: [],
    isCameraEnabled: false,
    isCoBrowsing: false,
    isMicrophoneEnabled: false,
    isReviewingDocuments: false,
    isScreenSharing: false,
    page: '',
    mediaError: null,
    selectedExtension: null,
    videoTrackExists: false,
  })

  const setSentryState = useCallback(
    (newState: Partial<SentryState> | SetStateAction<SentryState>) => {
      if (typeof newState === 'function') {
        setState(newState)
      } else {
        setState((prevState) => ({ ...prevState, ...newState }))
      }
    },
    [],
  )

  useEffect(() => {
    if (state) {
      Sentry.setContext('Application', state)
    }
  }, [state])

  return (
    <SentryContext.Provider
      value={{
        setSentryState,
      }}
    >
      {children}
    </SentryContext.Provider>
  )
}

export { SentryProvider, useSentry }
